/*jQuery-ui*/
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/droppable");

global.jQuery = require('jquery');
require('./scripts/assets/parallax-slider/js/jquery.cslider');

/*node_modules*/
import "node_modules/underscore/underscore.js";
import "node_modules/angular/angular.js";
import "node_modules/bootstrap/dist/js/bootstrap.js";
import "node_modules/angular-truncate-2/dist/angular-truncate-2";
import "node_modules/@uirouter/angularjs/release/angular-ui-router";
import "node_modules/angular-translate/dist/angular-translate.js";
import "node_modules/angular-file-upload/dist/angular-file-upload.js";
import "node_modules/angular-translate-loader-partial/angular-translate-loader-partial.js";
import "node_modules/angular-animate/angular-animate.js";
import "node_modules/angular-cookies/angular-cookies.js";
import "node_modules/angular-resource/angular-resource.js";
import "node_modules/angular-messages/angular-messages.js";
import "node_modules/angular-sanitize/angular-sanitize.js";
import "node_modules/angular-dialog-service/dist/dialogs.js";
import "node_modules/angular-dialog-service/dist/dialogs-default-translations.js";
import "node_modules/angular-clipboard/angular-clipboard.js";
import "node_modules/angular-formly/dist/formly";
import "node_modules/angular-formly-templates-bootstrap/dist/angular-formly-templates-bootstrap";
import "node_modules/angular-moment/angular-moment.js";
import "node_modules/angular-mocks/angular-mocks.js";
import "node_modules/ngstorage/ngStorage.js";
import "node_modules/angular-scenario/angular-scenario.js";
import "node_modules/moment/moment.js";
import "node_modules/diacritics/index.js";
import "node_modules/moment-duration-format/lib/moment-duration-format.js";
import "node_modules/moment-timezone/builds/moment-timezone-with-data-2012-2022.js";
import "node_modules/angular-touch/angular-touch.js";
import "node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js";
import "node_modules/angular-xeditable/dist/js/xeditable.js";
import "node_modules/angular-google-chart/ng-google-chart.js";
import "node_modules/angular-google-picker/dist/google-picker.js";
import "node_modules/ui-select/dist/select.js";
import "node_modules/angular-ui-sortable/dist/sortable.js";
import "node_modules/angular-dragdrop/src/angular-dragdrop.js";
import "node_modules/angular-toastr/dist/angular-toastr.tpls.js";
import "node_modules/flexslider/jquery.flexslider.js";

/*Dependency*/
import 'node_modules/dev-stack-ui-bootstrap/dist/main.js';
